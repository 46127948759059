<template>
  <div class="digital-twin">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner3.png" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <p>Digital twin</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">数字孪生</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="wp">
        <Loading class="load" v-if="$store.state.loadNum < 1"></Loading>
        <div id="cnt1"></div>
      </div>

      <!-- <el-popover placement="bottom" width="20" trigger="click">
        <div>
          <p>账号 : wzntest</p>
          <p>密码 : wzn***123</p>
        </div>
        <el-button type="primary" slot="reference">点击分配测试账号</el-button>
      </el-popover> -->
      <!-- <iframe
        ref="myFrame"
        class="my-frame"
        src="https://sr2x.com/szls/"
      ></iframe> -->
      <!-- src="http://222.88.186.81:23868/Home" -->
    </div>
  </div>
</template>
    
<script>
import Loading from "@/components/Pc/load.vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export default {
  name: "",
  components: {
    Loading: Loading,
  },
  data() {
    return {};
  },
  methods: {
    init() {
      let scene = new THREE.Scene();
      let light = new THREE.AmbientLight(0xadadad, 1);
      scene.add(light);
      // 平行光源
      let directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
      directionalLight.position.set(100, 100, 0);
      scene.add(directionalLight);
      //创建天空盒子
      const CubeTextureLoader = new THREE.CubeTextureLoader().setPath(
        "./texture/"
      );
      const TextCube = CubeTextureLoader.load([
        "1.jpg",
        "2.jpg",
        "3.jpg",
        "4.jpg",
        "5.jpg",
        "6.jpg",
      ]);
      scene.background = TextCube;
      let renderer = new THREE.WebGLRenderer({
        // 是否执行抗锯齿。默认为false.
        antialias: true,
        //  canvas是否包含alpha (透明度)。默认为 false
        alpha: true,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);

      renderer.outputEncoding = THREE.sRGBEncoding; //编码设置
      renderer.toneMapping = THREE.ACESFilmicToneMapping; //色调映射
      renderer.toneMappingExposure = 0.5; //曝光程度
      document.getElementById("cnt1").appendChild(renderer.domElement);
      let camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      camera.position.set(1500, 700, 121);
      let controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;

      controls.minDistance = 0;
      controls.maxDistance = 1800;
      const gltfLoader = new GLTFLoader();
      //材质
      gltfLoader.load("/model/map.glb", (gltf) => {
        console.log(gltf);
        scene.add(gltf.scene);
      });
      let _this = this;
      function render(time) {
        controls.update();
        renderer.render(scene, camera);
        _this.$store.state.loadNum = scene.children.length / 3;
        // 不断渲染
        requestAnimationFrame(render);
      }
      render();
      window.addEventListener("resize", () => {
        // 更新摄像头
        camera.aspect = window.innerWidth / window.innerHeight;
        // 更新摄像机投影矩阵
        camera.updateProjectionMatrix();
        // 更新渲染器
        renderer.setSize(window.innerWidth, window.innerHeight);
        // 设置渲染器像素比
        renderer.setPixelRatio(window.devicePixelRatio);
      });
    },
  },
  created() {},
  mounted() {
    this.init();
  },
};
</script>
    
<style scoped>
.digital-twin {
}
.my-frame {
  width: 100%;
  height: 100vh;
  /* height: calc(100vh + 70px); */
}
#cnt1 {
  width: 100%;
  height: 100vh;
}
.wp{
  position: relative;
}
.load{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>